import React, {useEffect} from "react"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import { basketActions, basketSelectors } from "../state/basket"
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import Layout from "../components/Layout"
import * as style from "./checkout.module.scss"
import { Card, Container, Col, Row } from "react-bootstrap"
import OrderSummary from "../components/BasketUI/OrderSummary"
import Checkmark from "../images/icon-cart-icon-check.inline.svg"
import { RenderCustomerDetails } from "../utils/checkoutHelper"
import { createDisplayPrice } from "../utils/productsHelper"
import HelpBlock from "../components/UI/HelpBlock/HelpBlock"
import CheckoutForm from "../components/BasketUI/CheckoutForm"
import {dataLayerUpdate} from "../api/dataLayer/gaDataLayer";

// TIP: dont call loadStripe within render moethod of component
//
const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY)

const CheckoutPage = ({
  basketItems,
  basketItemsCount,
  totalPrice,
  productData,
  resetBasket,
  basketTotal,
  basketTotalWithDiscount,
  couponCodeFromStore,
  couponValue,
  basketDeliveryOptions,
  additionalServices,
  isShippingSelected,
  calculateShippingFee,
  extraFees,
  removeCouponCode
}) => {
  useEffect(() => {
    basketItems &&
    dataLayerUpdate('add_payment_info',
        {'basketItems':basketItems, 'basketTotal':basketTotal, 'basketTotalWithDiscount':basketTotalWithDiscount}
    )
  }, [])
  if (typeof window === "undefined") {
    basketDeliveryOptions = {}
    basketDeliveryOptions.firstName = ""
    basketDeliveryOptions.lastName = ""
    basketDeliveryOptions.email = ""
    basketDeliveryOptions.deliveryMethod = ""
  }

  return (
    <Layout
      staticSeo={{ seoTitle: "Checkout", seoDescription: "Checkout" }}
      location={{ pathname: "checkout" }}>
      <div className={style.container}>
        <div className={`${style.bgImg}`}>
          <Container>
            <Row className="pt-4">
              <Col lg={{ span: 8, order: 1 }} sm={12} className="order-2 mb-4">
                <Card className={`px-xl-4 ${style.mainCard}`}>
                  {RenderCustomerDetails(basketDeliveryOptions)}
                  <hr />
                  <div className="px-3">
                    <h2 className="text-uppercase py-3">2 - PAYMENT </h2>
                    <div className=" media">
                      <div className="media-body d-flex my-3">
                        <h3 className="text-uppercase mr-2 pt-2">
                          choose your payment method
                        </h3>
                        <span className="mt-1">
                          <Checkmark />
                        </span>
                      </div>
                    </div>
                    <Elements stripe={stripePromise}>
                      <CheckoutForm
                        couponValue={couponValue}
                        productData={productData}
                        totalPrice={totalPrice}
                        basketTotal={basketTotal}
                        basketTotalWithDiscount={basketTotalWithDiscount}
                        removeCouponCode={removeCouponCode}
                        couponCodeFromStore={couponCodeFromStore}
                        resetBasket={resetBasket}
                        basketItems={basketItems}
                        basketDeliveryOptions={basketDeliveryOptions}
                        additionalServices={additionalServices}
                        basketItemsCount={basketItemsCount}
                        shippingFee={calculateShippingFee}
                        formatedShippingFee={isShippingSelected ? createDisplayPrice(calculateShippingFee, "checkout orderSummary") : "N/A"}
                        isShippingSelected={isShippingSelected}
                        extraFees={extraFees}
                      />
                    </Elements>
                  </div>
                </Card>
              </Col>
              <Col
                lg={{ span: 4, order: 2 }}
                sm={12}
                className="order-2 d-none d-lg-block">
                {/* TODO: update corresponding values to use discounted total price */}

                <OrderSummary
                  orderStage="checkout"
                  extraFees={extraFees}
                  basketItemsCount={basketItemsCount}
                  basketItems={basketItems}
                  basketTotal={totalPrice}
                  shippingFee={calculateShippingFee}
                  couponCodeFromStore={couponCodeFromStore}
                  basketTotalWithDiscount={basketTotalWithDiscount}
                  removeCouponCode={removeCouponCode}
                />

                {HelpBlock()}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </Layout>
  )
}

const mapStateToProps = createStructuredSelector({
  basketItems: basketSelectors.getItems,
  basketItemsCount: basketSelectors.getTotalItems,
  couponCodeFromStore: basketSelectors.getCouponCode,
  couponValue: basketSelectors.getCouponAmount,
  basketTotal: basketSelectors.getBasketTotal,
  basketTotalWithDiscount: basketSelectors.getBasketTotalWithDiscount,
  totalPrice: basketSelectors.getBasketTotal,
  productData: basketSelectors.getProductDataFromBasket,
  basketDeliveryOptions: basketSelectors.getDeliveryOptionDetails,
  additionalServices: basketSelectors.getAdditionalServices,
  isShippingSelected: basketSelectors.isShippingSelected,
  calculateShippingFee: basketSelectors.calculateShippingFee,
  extraFees: basketSelectors.getExtraFee
})

const mapDispatchToProps = {
  resetBasket: basketActions.resetBasket,
  removeCouponCode: basketActions.removeCouponCode,
}
export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPage)
