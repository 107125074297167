import axios from "axios";

export const createKlarnaSession = (data) => {

    const config = {
        baseURL: `${process.env.STRIPE_SVC_BACKEND_BASE_URL}`,
        auth: {
            username: `${process.env.STRIPE_SVC_BACKEND_USERNAME}`,
            password: `${process.env.STRIPE_SVC_BACKEND_PASSWORD}`
        },
    };

    return axios.post(`/api/v1/klarna-payments/session`, data, config);
};

export const createKlarnaOrder = (data) => {

    const config = {
        baseURL: `${process.env.STRIPE_SVC_BACKEND_BASE_URL}`,
        auth: {
            username: `${process.env.STRIPE_SVC_BACKEND_USERNAME}`,
            password: `${process.env.STRIPE_SVC_BACKEND_PASSWORD}`
        },
    };

    return axios.post(`/api/v1/klarna-payments/order`, data, config);
};
