import classNames from "classnames"
import { navigate, Link } from "gatsby"
import React, { Fragment, useState, useEffect, useLayoutEffect } from "react"
import {
  Row,
  Col,
  Alert,
  Button,
  Card,
  Form,
  Accordion,
  useAccordionToggle,
} from "react-bootstrap"
import {
  CardElement,
  useElements,
  useStripe,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js"

import { createStripePaymentIntent } from "../../api/stripePayments"
import {
  createKlarnaOrder,
  createKlarnaSession,
} from "../../api/klarnaPayments"
import {
  SameBillingAddressCheckbox,
  CARD_OPTIONS,
} from "../../utils/checkoutHelper"
import BagIcon from "../../images/icon-cart-bag-white.inline.svg"
import CreditCard from "../../images/credit-card-duotone.inline.svg"
import KlarnaLogoPink from "../../images/Klarna-pink.inline.svg"
import GooglePayMarkLogo from "../../images/google-pay-mark_800.inline.svg"
import LinkPaymentLogo from "../../images/link.inline.svg"
import { createDisplayPrice } from "../../utils/productsHelper"

import HelpBlock from "../UI/HelpBlock/HelpBlock"
import { Field } from "../UI/Forms/Field"
import { Loader } from "../UI/Loader"

import BillingAddressForm from "./BillingAddressForm"
import {
  MobileOrderSummary
} from "./OrderSummary"

import * as style from "../../pages/checkout.module.scss"

const CheckoutForm = ({
  productData,
  resetBasket,
  totalPrice,
  basketTotal,
  basketTotalWithDiscount,
  couponCodeFromStore,
  couponValue,
  removeCouponCode,
  basketDeliveryOptions,
  additionalServices,
  basketItemsCount,
  basketItems,
  isShippingSelected,
  shippingFee,
  formatedShippingFee,
  extraFees,
}) => {
  let additionalFees = 0
  if (extraFees && extraFees.length && extraFees[0]?.feeValue) {
    extraFees.length > 1
      ? (additionalFees = extraFees.reduce((a, b) => a.feeValue + b.feeValue))
      : (additionalFees = extraFees[0].feeValue)
  }
  const stripe = useStripe()
  const elements = useElements()
  const [socialPaymentRequest, setSocialPaymentRequest] = useState(null)
  const [socialPaymentId, setSocialPaymentId] = useState("")

  const [cardComplete, setCardComplete] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [checkoutError, setCheckoutError] = useState()
  const [termsCheckboxConfirmation, setTermsCheckboxConfirmation] = useState(
    false
  )
  const [formValidated, setFormValidated] = useState(false)
  const [scrollUp, setScrollUp] = useState(false)
  const [paymentOption, setPaymentOption] = useState("")
  const [klarnaPaymentOption, setKlarnaPaymentOption] = useState("")
  const [isLoading, setIsLoading] = useState(true)
  const [isRejected, setIsRejected] = useState(false)
  const isKlarnaAvailable = Boolean(Number(process.env.IS_KLARNA_ENABLED))
  const isSocialPayEnabled = true // todo: DO NOT COMMIT

  const [activeAccordionItem, setActiveAccordionItem] = useState(null)

  useEffect(() => {
    const script = document.createElement("script")
    const script2 = document.createElement("script")

    script.src = "https://x.klarnacdn.net/kp/lib/v1/api.js"
    script2.src =
      "https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"
    script.async = true
    script2.async = true

    document.body.appendChild(script)
    document.body.appendChild(script2)

    return () => {
      document.body.removeChild(script)
      document.body.removeChild(script2)
    }
  }, [])

  const initKlarna = () => {
    if (window && window.Klarna) {
      return createKlarnaSession({
        products: productData.products,
        discountCode: couponCodeFromStore,
      }).then(response => {
        window.Klarna.Payments.init({
          client_token: response.data.client_token,
        })

        return new Promise((resolve, reject) => {
          resolve(response.data)
        })
      })
    }
  }

  const getShippingAddress = () => {
    return {
      given_name: basketDeliveryOptions.firstName,
      family_name: basketDeliveryOptions.lastName,
      email: basketDeliveryOptions.email,
      street_address:
        basketDeliveryOptions.addressLine1 +
        " " +
        basketDeliveryOptions.addressLine2,
      postal_code: basketDeliveryOptions.postcode,
      city: basketDeliveryOptions.city,
      phone: basketDeliveryOptions.phoneNumber,
      country: "GB",
    }
  }

  const submitKlarnaOrder = () => {
    // const billingAddress = getShippingAddress();
    // billingAddress: billingAddress,
    // billing_address: billingAddress,
    const shippingAddress = getShippingAddress()

    window &&
      window.Klarna &&
      window.Klarna.Payments &&
      window.Klarna.Payments.authorize(
        {
          payment_method_category: window.klarnaSelectedPaymentId,
          purchase_country: "GB",
        },
        {
          shipping_address: shippingAddress,
          order_amount: window.klarnaSessionResponse.orderAmount,
          order_tax_amount: window.klarnaSessionResponse.orderTaxAmount,
          order_lines: window.klarnaSessionResponse.orderLines,
        },
        function(res) {
          if (res.approved) {
            createKlarnaOrder({
              authorizationToken: res.authorization_token,
              products: productData.products,
              shippingAddress: shippingAddress,
              discountCode: couponCodeFromStore,
              confirmationUrl: process.env.SITE_URL + "/paymentSuccess",
            }).then(response => {
              const orderId =
                response.data && response.data.order_id
                  ? response.data.order_id
                  : 122
              resetBasket()
              navigate("/paymentSuccess", {
                state: {
                  isShippingSelected: isShippingSelected,
                  basketItems: basketItems,
                  result: {
                    orderId: orderId,
                    orderDate: new Date().toISOString(),
                    total: basketTotalWithDiscount,
                    subtotal: totalPrice,
                    couponValue: couponValue,
                  },
                  basketDeliveryOptions: basketDeliveryOptions,
                  additionalServices: additionalServices,
                  shippingFee: formatedShippingFee,
                },
              })
            })
          }
        }
      )
  }

  const klarnaPaymentOptionIdPrefix = "klarna-payment-option-"

  const loadKlarna = identifier => {
    let elementIdentifier = "\u0023" + klarnaPaymentOptionIdPrefix + identifier
    const paymentOption = document.getElementById(
      klarnaPaymentOptionIdPrefix + identifier
    )
    console.log(
      "created at loadKlarna, payment option should exist",
      paymentOption,
      "will search for",
      window.Klarna
    )

    try {
      window &&
        window.Klarna &&
        window.Klarna.Payments.load(
          {
            container: elementIdentifier,
            payment_method_category: identifier,
          },
          function(res) {
            if (res.show_form) {
              console.log("payment method is approved to be shown", identifier)
            } else {
              console.log(
                "payment method failed approval to be shown",
                identifier
              )
            }
          }
        )
    } catch (e) {
      // Handle error. The load~callback will have been called
      // with "{ show_form: false }" at this point.
      console.error("something went wrong...", e)
    }
    console.log("klarna loaded")
  }

  const selectKlarnaPayment = identifier => {
    window.klarnaSelectedPaymentId = identifier // this needs to be at the end as it changes the state and forces component to redraw

    const paymentOption = document.getElementById(
      klarnaPaymentOptionIdPrefix + identifier
    )
    console.log("setting klarna payment option", identifier, paymentOption)
    paymentOption &&
      paymentOption.setAttribute("style", "display: block") &&
      console.log(
        "chosen option is ",
        identifier,
        paymentOption.getAttribute("data-is-enabled")
      )
    const klarnaPaymentOptionsContainer = document.getElementById(
      "klarna-buy-button-container"
    )
    klarnaPaymentOptionsContainer.setAttribute("style", "display: block")

    window.klarnaPaymentMethodIdentifiers.forEach(paymentMethodIdentifier => {
      if (identifier !== paymentMethodIdentifier) {
        const paymentMethodContainer = document.getElementById(
          klarnaPaymentOptionIdPrefix + paymentMethodIdentifier
        )
        if (paymentMethodContainer) {
          paymentMethodContainer.style.display = "none"
        }
      }
    })

    loadKlarna(identifier)
  }

  const RenderSocialPayments = () => {
    if (isSocialPayEnabled) {
      return <div style={{ width: "100%" }} id="pr-google-pay-button"></div>
    }
    return null
  }

  const RenderKlarnaElement = () => {
    // window.klarnaSessionResponseResponse
    return (
      <>
        {isLoading && <Loader center={true} />}

        {isRejected && (
          <Alert variant="danger">
            Apologies but there has been an error. Please refresh the page and
            try again.
          </Alert>
        )}
        {typeof window !== "undefined" &&
          !isRejected &&
          window.klarnaSessionResponse &&
          window.klarnaSessionResponse.paymentMethods &&
          window.klarnaSessionResponse.paymentMethods.map(paymentMethod => {
            console.log("drawing payment method", paymentMethod.identifier)
            return (
              <>
                <Button
                  key={paymentMethod.identifier}
                  className={`px-4 m-2 ${style.klarnaPayBtn}`}
                  onClick={() => selectKlarnaPayment(paymentMethod.identifier)}>
                  <BagIcon />
                  <span className="ml-2 align-middle">
                    {paymentMethod.name}
                  </span>
                </Button>
                <div
                  id={`${klarnaPaymentOptionIdPrefix}${paymentMethod.identifier}`}
                  style={{
                    display:
                      klarnaPaymentOption === paymentMethod.identifier
                        ? "none"
                        : "block",
                  }}
                />
              </>
            )
          })}
        {!isRejected && !isLoading && (
          <Row className={`d-flex flex-column`}>
            <div
              id="klarna-buy-button-container"
              style={{
                width: "auto",
                display: klarnaPaymentOption === "" ? "none" : "block",
              }}>
              <Button
                className={`px-4 m-2  authorize ${style.klarnaPayBtn}`}
                onClick={() => submitKlarnaOrder()}>
                <span className="ml-2 align-middle">
                  Buy now, Pay later with Klarna.
                </span>
              </Button>
            </div>
          </Row>
        )}
      </>
    )
  }

  const handleCardDetailsChange = ev => {
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError()
    ev.complete ? setCardComplete(true) : setCardComplete(false)
  }

  const createBasePaymentDataObject = () => {
    let paymentData = productData
    paymentData.products = paymentData.products.map(el =>
        typeof el.associatedProductId === "string"
            ? {
              ...el,
              associatedProductId: Number(el.associatedProductId.slice(1)),
            }
            : el
    )

    paymentData.receiptEmail = basketDeliveryOptions.email
    if (couponCodeFromStore) {
      paymentData.discountCode = couponCodeFromStore
    }
    basketDeliveryOptions.paymentMethod = "Card"
    basketDeliveryOptions.paymentMethodTitle = "Credit / Debit card"

    paymentData.orderDetails = basketDeliveryOptions

    if (basketDeliveryOptions.pickupLocation && !basketDeliveryOptions.addressLine1) {
      paymentData.orderDetails.shippingAddress = {
        firstName: basketDeliveryOptions.firstName,
        lastName: basketDeliveryOptions.lastName,
        address1: process.env.SHEPHERDS_BUSH_STREET,
        city: process.env.SHEPHERDS_BUSH_CITY,
        postcode: process.env.SHEPHERDS_BUSH_POST_CODE,
        country: "GB",
        email: basketDeliveryOptions.email,
        phone: process.env.SHEPHERDS_BUSH_PHONE_NUMBER,
      }
    } else {
      paymentData.orderDetails.shippingAddress = {
        firstName: basketDeliveryOptions.firstName,
        lastName: basketDeliveryOptions.lastName,
        address1: basketDeliveryOptions.addressLine1,
        address2: basketDeliveryOptions.addressLine2,
        city: basketDeliveryOptions.city,
        postcode: basketDeliveryOptions.postcode,
        country: "GB",
        email: basketDeliveryOptions.email,
        phone: basketDeliveryOptions.phoneNumber,
      }
    }

    return paymentData;
  }

  const handleSubmit = async ev => {
    ev.preventDefault()
    // form validation
    const form = ev.currentTarget

    //Checking if card is completed
    if (!cardComplete) {
      ev.stopPropagation()
      return
    }
    if (form.checkValidity() === false) {
      setFormValidated(false)
      setScrollUp(false)
      return
    } else {
      ev.stopPropagation()
      setFormValidated(true)
      setScrollUp(true)
    }

    let paymentData = createBasePaymentDataObject();

    const billingDetails = {
      name: form.cardholderName.value,
      email: basketDeliveryOptions.email,
      address: {
        city: form.city.value,
        line1: form.addressLine1.value,
        country: "GB",
        postal_code: basketDeliveryOptions.postcode,
      },
    }
    if (basketDeliveryOptions.phoneNumber) {
      billingDetails.phone = basketDeliveryOptions.phoneNumber
    }
    if (form.addressLine2.value) {
      billingDetails.address.line2 = form.addressLine2.value
    }

    const cardElement = elements.getElement(CardElement)

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    if (checkoutError) {
      elements.getElement("card").focus()
      return
    }

    if (cardComplete) {
      setProcessing(true)
    }
    paymentData.orderDetails.billingAddress = {
      firstName: basketDeliveryOptions.firstName,
      lastName: basketDeliveryOptions.lastName,
      address1: form.addressLine1.value,
      address2: form.addressLine2.value,
      city: form.city.value,
      postcode: form.postcode.value,
      country: "GB",
      email: basketDeliveryOptions.email,
      phone: basketDeliveryOptions.phoneNumber,
    }

    let orderId = "",
      orderDate = "",
      total = ""
    if (paymentOption === "stripe") {
      // Stripe
      createStripePaymentIntent(paymentData)
        .then(response => {
          const secret = response.data.stripePaymentSecret
          orderId = response.data.orderId
          orderDate = response.data.orderDate
          total = response.data.total
          return secret
        })
        .then(secret => {
          stripe
            .confirmCardPayment(secret, {
              payment_method: {
                card: cardElement,
                //card: elements.getElement('card'),
                billing_details: billingDetails,
              },
            })

            .then(function(result) {
              if (result.error) {
                // Show error to your customer (e.g., insufficient funds)
                console.error(result.error.message)
                setProcessing(false)
                navigate("/paymentFailure", {
                  state: { errorMessage: result.error.message },
                })
              } else {
                // The payment has been processed!
                if (result.paymentIntent.status === "succeeded") {
                  resetBasket()
                  navigate("/paymentSuccess", {
                    state: {
                      isShippingSelected: isShippingSelected,
                      basketItems: basketItems,
                      result: {
                        orderId,
                        orderDate,
                        total,
                        basketTotalWithDiscount,
                        extraFees,
                        subtotal: totalPrice,
                        couponValue: couponValue,
                      },
                      basketDeliveryOptions: basketDeliveryOptions,
                      additionalServices: additionalServices,
                      shippingFee: formatedShippingFee,
                    },
                  })
                }
              }
            })
        })
    } else {
      // Klarna
      // submit Klarna method here
    }
  }

  const klarnaHandler = async () => {
    console.log("initialising Klarna")
    initKlarna()
      .then(data => {
        console.log(data, "klarna session response")
        const clientToken = data.client_token
        const orderAmount = data.order_amount
        const orderTaxAmount = data.order_tax_amount
        const orderLines = data.order_lines
        const paymentMethods = data.payment_method_categories
        const klarnaSessionResponse = {
          clientToken,
          orderAmount,
          orderTaxAmount,
          orderLines,
          paymentMethods,
        }
        window.klarnaPaymentMethodIdentifiers = []
        paymentMethods.forEach(paymentMethod => {
          window.klarnaPaymentMethodIdentifiers.push(paymentMethod.identifier)
        })
        console.log("setting klarna session", klarnaSessionResponse)
        window.klarnaSessionResponse = klarnaSessionResponse
        setIsLoading(false)
      })
      .catch(error => {
        setIsLoading(false)
        setIsRejected(true)
        console.error("initalising Klarna failed", error)
      })
  }

  const stripeHandler = async () => {
    const amount = Math.trunc(parseFloat(basketTotalWithDiscount) * 100)
    const paymentRequest = await stripe.paymentRequest({
      country: "GB",
      currency: "gbp",
      total: {
        label: "Total to pay",
        amount: amount,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    })

    const btn =
        elements.getElement("paymentRequestButton") == null
            ? elements.create("paymentRequestButton", {
              paymentRequest: paymentRequest,
            })
            : elements.getElement("paymentRequestButton")

    // Check the availability of the Payment Request API.
    paymentRequest.canMakePayment().then(result => {
      if (result) {
        if ((result.googlePay && result.link) || result.googlePay) {
          setSocialPaymentId("googlePay")
        } else if (result.link) {
          setSocialPaymentId("link")
        }
        if (result.applePay) {
          setSocialPaymentId("applePay")
        }
        if (result.googlePay || result.link) {
          setSocialPaymentRequest(paymentRequest)
          btn.mount("#pr-google-pay-button")
        }
      } else {
        console.warn(
            " pr.canMakePayment() Result is not present (Stripe)",
            result
        )
      }
    })

    let orderId = "",
        orderDate = "",
        total = ""
    paymentRequest.on("paymentmethod", async (e) => {
      let paymentData = createBasePaymentDataObject();
      await createStripePaymentIntent(paymentData)
          .then(response => {
            const secret = response.data.stripePaymentSecret
            orderId = response.data.orderId
            orderDate = response.data.orderDate
            total = response.data.total
            return secret
          })
          .then(secret => {
            stripe.confirmCardPayment(secret, {
                  payment_method: e.paymentMethod.id,
                }, {handleActions: false}).then(function (result) {
                  if (result.error) {
                    e.complete('fail')
                    // Show error to your customer (e.g., insufficient funds)
                    console.error(result.error.message)
                    setProcessing(false)
                    navigate("/paymentFailure", {
                      state: {errorMessage: result.error.message},
                    })
                  } else {
                    if (result.paymentIntent.status === 'requires_action') {
                      stripe.confirmCardPayment(secret)
                    }
                    // The payment has been processed!
                    e.complete('success')
                    // We probably need to assume it's a success now as result.paymentIntent.status === 'requires_action' seems specific to Google Pay
                    // TODO check the above https://youtu.be/GERlC3PxKgY?t=1055
                    // if (result.paymentIntent.status === "succeeded") {
                    // The payment has been processed!
                    e.complete('success')
                    resetBasket()
                    navigate("/paymentSuccess", {
                      state: {
                        isShippingSelected: isShippingSelected,
                        basketItems: basketItems,
                        result: {
                          orderId,
                          orderDate,
                          total,
                          basketTotalWithDiscount,
                          extraFees,
                          subtotal: totalPrice,
                          couponValue: couponValue,
                        },
                        basketDeliveryOptions: basketDeliveryOptions,
                        additionalServices: additionalServices,
                        shippingFee: formatedShippingFee,
                      },
                    })
                  }
                })
          })
    })
  }

  useLayoutEffect(() => {
    if (stripe) {
      stripeHandler()
    }
  }, [stripe, paymentOption])

  const buttonClassnames = classNames(
    " my-0",
    !stripe || checkoutError || !termsCheckboxConfirmation || processing
      ? "disabled"
      : ""
  )

  const PaymentMethodToggle = ({ children, eventKey }) => {
    const handleChange = useAccordionToggle(eventKey, () => {
      if (eventKey !== paymentOption) {
        setPaymentOption(eventKey)

        if (eventKey === "klarna") {
          klarnaHandler()
        }
      }
    })

    return (
      <Form.Check
        inline
        className={style.accordionLabel}
        label={children}
        type="radio"
        checked={eventKey === activeAccordionItem}
        name="paymentMethodOptions"
        id={`accordion-for-${eventKey}`}
        onChange={handleChange}
      />
    )
  }

  return (
    <Form
      id="topForm"
      noValidate
      validated={formValidated}
      onSubmit={handleSubmit}>
      <>
        <Accordion
          className="mb-5"
          activeKey={activeAccordionItem}
          onSelect={e => setActiveAccordionItem(e)}>
          {socialPaymentRequest !== null && (
            <Card>
              <Card.Header className="bg-white">
                <PaymentMethodToggle eventKey="social">
                  <div
                    className={`${style.accordionLogoContainer} d-inline-block mx-3`}>
                    {socialPaymentId === "googlePay" && (
                      <GooglePayMarkLogo width={65} height={40} />
                    )}
                    {socialPaymentId === "link" && (
                      <LinkPaymentLogo width={50} />
                    )}
                  </div>
                  {socialPaymentId === "googlePay" && "Google Pay"}
                  {socialPaymentId === "link" && "Pay faster"}
                </PaymentMethodToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="social">
                <Card.Body>
                  <RenderSocialPayments />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          )}

          <Card>
            <Card.Header className="bg-white">
              <PaymentMethodToggle eventKey="stripe">
                <div
                  className={`${style.accordionLogoContainer} d-inline-block mx-3`}>
                  <CreditCard width={40} />
                </div>
                Credit Card
              </PaymentMethodToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="stripe">
              <Card.Body>
                <p className="text-uppercase h3 pb-3 pt-4 d-block">
                  credit card information
                </p>
                <div>
                  <Row>
                    <Col md={6}>
                      <Field
                        label="Cardholder Name*"
                        id="cardholderName"
                        type="text"
                        placeholder="E.g Arianne Whittaker"
                        required
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <CardElement
                        options={CARD_OPTIONS}
                        id="cardNumber"
                        onChange={handleCardDetailsChange}
                        className={style.cardInput}
                      />
                    </Col>
                  </Row>

                  <p className="text-uppercase h3 pb-3 pt-4">Billing address</p>
                  {SameBillingAddressCheckbox(basketDeliveryOptions)}
                  <BillingAddressForm />

                  <MobileOrderSummary
                    orderStage="checkout"
                    basketTotal={basketTotal}
                    basketItems={basketItems} 
                    basketItemsCount={basketItemsCount}
                    basketTotalWithDiscount={basketTotalWithDiscount}
                    shippingFee={shippingFee}
                    isShippingSelected={isShippingSelected}
                    removeCouponCode={removeCouponCode}
                    couponCodeFromStore={couponCodeFromStore}
                    extraFees={extraFees}
                  />
                  
                  <Row className="d-lg-block px-3 my-4">
                    <Form.Check
                      type="checkbox"
                      id="confirmation"
                      required
                      label={
                        <div>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: process.env.TERMS_AND_CONDITIONS,
                            }}
                          />
                        </div>
                      }
                      onClick={() =>
                        setTermsCheckboxConfirmation(!termsCheckboxConfirmation)
                      }
                    />
                  </Row>
                  <Row className="d-lg-flex align-items-baseline justify-content-between px-3 mb-4">
                    <div className="d-lg-flex d-none  align-items-baseline">
                      <p className="h3 pr-3">TOTAL</p>
                      <p>
                        <span className="h2">
                          {extraFees.length
                            ? createDisplayPrice(
                                basketTotalWithDiscount + additionalFees,
                                "checkoutForm"
                              )
                            : createDisplayPrice(
                                basketTotalWithDiscount,
                                "checkoutForm"
                              )}
                        </span>
                      </p>
                    </div>
                    {scrollUp ? (
                      <Button
                        className={`${buttonClassnames} ${style.orderBtn}`}
                        variant="primary"
                        disabled={processing}
                        type="submit">
                        {processing ? (
                          "Processing..."
                        ) : (
                          <Fragment>
                            <span className="mr-3" aria-label="Shopping bag">
                              <BagIcon />
                            </span>
                            Place Order
                          </Fragment>
                        )}
                      </Button>
                    ) : (
                      <Button
                        className={`${buttonClassnames} ${style.orderBtn}`}
                        variant="primary"
                        type="submit"
                        disabled={processing}
                        onClick={() => {
                          let elmnt = document.getElementById("topForm")
                          elmnt.scrollIntoView()
                        }}>
                        {processing ? (
                          "Processing..."
                        ) : (
                          <Fragment>
                            <span className="mr-3" aria-label="Shopping bag">
                              <BagIcon />
                            </span>
                            Place Order
                          </Fragment>
                        )}
                      </Button>
                    )}
                  </Row>
                  <p className="gray-600 mb-5 d-lg-none text-center">
                    By clicking Place Order you accept our{" "}
                    <Link to="/privacy-policy" className="gray-600">
                      Terms & Conditions
                    </Link>
                  </p>
                  <div className="d-lg-none">{HelpBlock()}</div>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          {isKlarnaAvailable && (
            <Card>
              <Card.Header className="bg-white">
                <PaymentMethodToggle eventKey="klarna">
                  <div className="d-flex align-items-center">
                    <div
                      className={`${style.accordionLogoContainer} d-inline-block mx-3`}>
                      <KlarnaLogoPink width={64} />
                    </div>
                    <div className="d-flex flex-column">
                      <div>Klarna</div>
                      <small>Pay in installments with Klarna</small>
                    </div>
                  </div>
                </PaymentMethodToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="klarna">
                <Card.Body>
                  <RenderKlarnaElement />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          )}
        </Accordion>
      </>
    </Form>
  )
}

export default CheckoutForm
