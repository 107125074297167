import React from 'react'
import {Row, Col, Form } from 'react-bootstrap';

const BillingAddressForm = () => {
  return (
    <>
      <Row>
      <Col>
        <Form.Group>
          <Form.Label>Address Line 1*</Form.Label>
          <Form.Control
              type="text"
              name="addressLine1"
              id="addressLine1"
              placeholder="E.g 68 Charlotte St"
              className="rounded-0 p-3"
              required
          />
        </Form.Group>
      </Col>
    </Row>
    <Row>
      <Col>
        <Form.Group>
          <Form.Label>Address Line 2</Form.Label>
          <Form.Control
              type="text"
              name="addressLine2"
              id="addressLine2"
              placeholder="Bloomsbury"
              className="rounded-0 p-3"
          />
        </Form.Group>
      </Col>
    </Row>
    <Row>
      <Col xs={10} lg={6}>
        <Form.Group >
          <Form.Label>Postcode*</Form.Label>
          <Form.Control
              type="text"
              name="postcode"
              id="postcode"
              placeholder="E.g E1 6AN"
              className="rounded-0 p-3"
              required
          />
        </Form.Group>
      </Col>
      <Col xs={12} lg={6}>
        <Form.Group controlId="city">
          <Form.Label>City*</Form.Label>
          <Form.Control
              type="text"
              name="city"
              placeholder="E.g London"
              className="rounded-0 p-3"
              required
          />
        </Form.Group>
      </Col>
    </Row>
    </>
  )
}
export default BillingAddressForm